
import { Vue, Options } from 'vue-class-component';
import UserController from '@/AppClubCarby/Controller/UserController';
import Swal from 'sweetalert2';
import GasStationController from '@/AppClubCarby/Controller/GasStationController';
import { GasStationInterface } from '@/models/GasStationModel';
import Loading from 'vue3-loading-overlay';
import ViaCepService from '@/services/ViaCepService';
import Multiselect from '@vueform/multiselect';
import NameInputComponent from '@/AppClubCarby/Components/NameInputComponent/NameInputComponent.vue';
import useVuelidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
import { cpfRemoveMask } from '@/mixins';

const newUser = {
  user: {
    type: 'MANAGER',
    id: null,
    birthDate: null,
    cpf: null,
    email: null,
    gender: null,
    name: null,
    nationality: null,
    password: null,
    phone: null,
    address: {
      cep: null,
      city: null,
      country: null,
      district: null,
      latitude: null,
      longitude: null,
      number: null,
      street: null,
      surname: null,
      uf: null,
      complement: null,
    },
  },
  gasStations: [],
};

@Options({
  data() {
    return {
      v$: useVuelidate(),
      gasStationNames: [],
      userController: new UserController(),
      viaCepService: new ViaCepService(),
      gasStationController: new GasStationController(),
      gasStation1: [],

      gasStation2: {
        id: null,
      },

      gasStation3: {
        id: null,
      },

      gasStation4: {
        id: null,
      },
      gasStations: [],
      isLoading: false,
      passwordVerify: null,
      userData: {
        user: {
          type: 'MANAGER',
          id: null,
          birthDate: null,
          cpf: null,
          email: null,
          gender: null,
          name: null,
          nationality: null,
          password: null,
          phone: null,
          address: {
            cep: null,
            city: null,
            country: null,
            district: null,
            latitude: null,
            longitude: null,
            number: null,
            street: null,
            surname: null,
            uf: null,
            complement: null,
          },
        },
        gasStations: [],
      },
    };
  },
  validations() {
    return {
      userData: {
        user: {
          name: { required },
          email: { required, email },
          cpf: { required, minLength: minLength(11) },
        },
      },
    };
  },
  computed: {
    edit() {
      return this.$route.params.id ? true : false;
    },
  },
  components: {
    Loading,
    Multiselect,
    NameInputComponent,
  },
  created() {
    if (this.edit) {
      this.userController
        .getOneUserById('MANAGER', this.$route.params.id)
        .then((item) => {
          this.userData.user = item.user;
          this.userData.gasStations = item.gasStations.map((data) => {
            return data.id;
          });
          console.log(this.userData.gasStations);
        });
    } else {
      this.userData = newUser;
    }
    this.getGasStations();
  },
  methods: {
    getGasStationSelectedNames(id) {
      const data = this.gasStationNames.find((item) => {
        return item ? item.id == parseInt(id) : null;
      });

      return data.fantasyName;
    },
    async getGasStations() {
      const data = await this.gasStationController
        ._getGasStation()
        .then((item) => {
          this.gasStationNames = item.data.content;
          return item;
        })
        .then((item: GasStationInterface) => {
          return item.data.content.map((result) => {
            return {
              value: result.id,
              name: result.fantasyName,
              label: result.fantasyName,
            };
          });
        });
      this.gasStations = data;
    },
    selectGasStation(e: any) {
      this.gasStations.forEach((gasStation: any) => {
        if (gasStation.id == e.target.value) {
          this.selectedGasStation.id.push(gasStation.id);
        }
      });
    },
    clicou() {
      console.log(this.gasStation1);
      console.log(this.gasStation2);
    },
    includeGasStation(e) {
      this.gasStations.forEach((gasStation: any) => {
        if (gasStation.id == e.target.value) {
          this.selectedGasStation.id.push(gasStation.id);
        }
      });
    },
    selectCep() {
      if (this.userData.user.address.cep.length >= 8) {
        this.isLoading = true;
        this.viaCepService
          ._getCep(this.userData.user.address.cep)
          .then((address: any) => {
            this.userData.user.address.city = address.data.city;
            this.userData.user.address.uf = address.data.state;
            this.userData.user.address.street = address.data.street;
            this.userData.user.address.district = address.data.neighborhood;
            this.userData.user.address.surname = `Endereço ${this.userData.user.name}`;
            this.isLoading = false;
          });
      }
    },

    sendForm() {
      this.v$.$validate();

      if (this.v$.error) {
        return;
      }

      if (this.passwordVerify !== this.userData.user.password && !this.edit) {
        Swal.fire({
          title: 'Oops!',
          text: 'As senhas não coincidem, por favor verifique!',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        return false;
      }

      this.userData.gasStations.push(this.gasStation1);
      this.isLoading = true;

      if (this.edit) {
        const managerId = this.$route.params.id;
        this.userData.gasStations = this.userData.gasStations.reduce(
          (n, item) => {
            return typeof item == 'number' ? [...n, { id: item }] : [...n];
          },
          []
        );

        this.userEdit = { id: managerId, ...this.userData };
        this.userEdit.user.cpf = cpfRemoveMask(this.userEdit.user.cpf);
        this.userController
          .editUser('MANAGER', this.userEdit)
          .then(() => {
            this.isLoading = false;
            Swal.fire({
              customClass: {
                actions: "modal-register-actions",
                confirmButton: "modal-btn modal-btn-confirm",
                icon: "modal-register-icon",
                title: "modal-register-title",
                container: "modal-register success",
                closeButton: "modal-register-close",
              },
              title: "Yay!",
              text: "Usuário editado com sucesso!",
              icon: "success",
              confirmButtonText: "Ok",
            });
          })
          .catch((error: any) => {
            this.isLoading = false;
            Swal.fire({
              customClass: {
                actions: "modal-register-actions",
                confirmButton: "modal-btn modal-btn-confirm",
                icon: "modal-register-icon",
                title: "modal-register-title",
                container: "modal-register error",
                closeButton: "modal-register-close",
              },
              title: "Oops!",
              text: "As senhas não coincidem, por favor verifique!",
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      } else {
        this.userData.gasStations = this.userData.gasStations.reduce(
          (n, item) => {
            return typeof item == 'number' ? [...n, { id: item }] : [...n];
          },
          []
        );
        this.userData.user.cpf = cpfRemoveMask(this.userData.user.cpf);
        this.userController
          .setUser(this.userData.user.type, this.userData)
          .then(() => {
            this.isLoading = false;
            Swal.fire({
              customClass: {
                actions: "modal-register-actions",
                confirmButton: "modal-btn modal-btn-confirm",
                icon: "modal-register-icon",
                title: "modal-register-title",
                container: "modal-register success",
                closeButton: "modal-register-close",
              },
              title: "Yay!",
              text: "Usuário cadastrado com sucesso!",
              icon: "success",
              confirmButtonText: "Ok",
            });
          });
      }
    },
  },
})
export default class AppUsersManagerRegister extends Vue { }
