import store from "@/store";
import UserModel from "../Model/UserModel";

const userModel = new UserModel();
export default class UserController {
  public login(email: string, pass: string, keepLoggedIn: boolean) {
    return userModel.login(email, pass, keepLoggedIn).then(
      (res) => {
        return {
          tipo: "sucesso",
          data: res.data,
        };
      },
      (err) => {
        return {
          tipo: "erro",
          data: err.response.data,
        };
      }
    );
  }

  public refreshToken() {
    userModel.refreshToken(store.state.token).then((res) => {
      store.dispatch("updateToken", res.headers.authorization);
    });
  }

  public getUsersByType(type: string, page?: number, name?: string) {
    if (!page) {
      page = 0;
    }
    return userModel.getUsersByType(type, page, name).then((data) => {
      return data.data;
    });
  }

  public getAttendantsGasStationId(
    gasStationId: number,
    page?: number,
    name?: string
  ) {
    if (!page) page = 0;
    return userModel
      .getAttendantsGasStationId(gasStationId, page, name)
      .then((data) => {
        return data.data;
      });
  }

  public getAttendantsByGasStation(gasStationId: number) {
    return userModel.getAttendantByGasStation(gasStationId).then((data) => {
      return data.data;
    });
  }

  public async getOneUserByEmail(role?: string, email?: string) {
    const receivedRole = role ? role : localStorage.getItem("ROLE");

    let roleLower = receivedRole.replace("ROLE_", "");
    roleLower = roleLower.toLowerCase();

    const receivedEmail = email ? email : store.state.decodedToken.sub;

    let response;

    await userModel.getOneUserByEmail(roleLower, receivedEmail).then((res) => {
      response = res;
    });

    return response;
  }

  public async getOneUserByCPF(role?: string, cpf?: string) {
    const receivedRole = role ? role : localStorage.getItem("ROLE");

    let roleLower = receivedRole.replace("ROLE_", "");
    roleLower = roleLower.toLowerCase();

    const receivedCPF = cpf ? cpf : store.state.decodedToken.sub;

    let response;

    await userModel.getOneUserByCPF(roleLower, receivedCPF).then((res) => {
      response = res;
    });

    return response;
  }

  public async getOneUserById(role: string, id: number) {
    const receivedRole = role;

    const roleLower = receivedRole.toLowerCase();

    let response;

    await userModel.getOneUserById(roleLower, id).then((res) => {
      response = res;
    });
    return response;
  }

  public async getAllUsers() {
    return await userModel.getAllUsers().then((res) => {
      return res.data;
    });
  }

  public async setUser(type: string, data: any) {
    const receivedRole = type;

    const roleLower = receivedRole.toLowerCase();

    return await userModel.setUser(roleLower, data);
  }

  public async editUser(type: string, data: any) {
    const receivedRole = type;

    const roleLower = receivedRole.toLowerCase();

    return await userModel.editUser(roleLower, data);
  }

  public async editTargets(idTarget:number, data:any){
    return await userModel.editTargets(idTarget,data)
  }

  public async recoverPassword(email: string) {
    return await userModel.recoverPassword(email);
  }
  public async comparePassword(userId: number, password: any) {
    return userModel.comparePassword(userId, password);
  }

  public async pajetPreRegister(cpf) {
    return userModel.pajetPreRegister(cpf);
  }

  public async confirmPreRegister(data) {
    return await userModel.confirmPreRegister(data);
  }
}
