
import Loading from 'vue3-loading-overlay';
import { Vue, Options } from 'vue-class-component';
import ClientPurchaseController from '@/AppClubCarby/Controller/ClientPurchaseController';
import PartnerExchangeController from '@/AppClubCarby/Controller/PartnerExchangeController';
import UserController from '@/AppClubCarby/Controller/UserController';
import {
  cpfRemoveMask,
  cpfMask,
  formatCurrency,
  formatReal,
  removeCurrency,
  formatCurrencyWithoutSymbol,
} from '@/mixins';
import CardComponent from '@/AppClubCarby/Components/CardComponent/CardComponent.vue';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import Swal from 'sweetalert2';
import CurrencyInput from '@/AppClubCarby/Components/CurrencyInput/CurrencyInput.vue';

@Options({
  beforeRouteEnter(to, from, next) {
    let role = localStorage.getItem('ROLE');
    if (role === 'ROLE_PARTNER') {
      next();
    } else {
      next('/not-found');
    }
  },
  components: {
    CardComponent,
    CurrencyInput,
    Loading,
  },
  created() {
    this.userController.getOneUserByEmail().then((res) => {
      this.selectedPartner = res;
    });
  },
  data() {
    return {
      isLoading: false,
      v$: useVuelidate(),
      client: {
        cpf: null,
        email: null,
        id: null,
        name: null,
        totalDiscounts: null,
        totalEcopoints: null,
      },
      selectedClient: {},
      clientCpf: '',
      clientPurchaseController: new ClientPurchaseController(),
      partnerExchangeController: new PartnerExchangeController(),
      userController: new UserController(),
      cashback: 0,
      selectedPartner: {
        user: {
          name: '',
        },
      },
      amountCashbackValue: 0,
    };
  },
  validations() {
    return {
      clientCpf: {
        required,
        minLengthValue: minLength(14),
      },
    };
  },
  methods: {
    async searchClient() {
      this.v$.$touch();
      if (this.v$.$error) {
        Swal.fire({
          title: 'Oops!',
          text: 'Preencha o campo de CPF corretamente!',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        return;
      } else {
        this.isLoading = true;
        await this.userController
          .getOneUserByCPF('client', cpfRemoveMask(this.clientCpf))
          .then((item: any) => {
            this.selectClient(item.data[0]);
            
            this.isLoading = false;
          }).catch((err: any) => {
            Swal.fire({
              title: 'Oops!',
              text: 'Algum erro inesperado aconteceu!',
              icon: 'error',
              confirmButtonText: 'Ok',
            });
            this.isLoading = false
          });
      }
    },
    selectClient(client: any) {
      
      if (client == undefined) {
        Swal.fire({
          title: 'Oops!',
          text: 'CPF não encontrado',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        this.isLoading = false
        return;
      }
      if (client) {
        this.userController
          .getOneUserById("client", client.id)
          .then((item: any) => {
            this.selectedClient = item;
            this.amountCashbackValue = item.cashbackBalance;
          });
      }
    },
    returnCpf(cpf: string) {
      return cpfMask(cpf);
    },
    returnCurrency(money: number) {
      return formatCurrency(money);
    },
    finishTransaction() {
      let data = {
        catalogPartner: {
          id: 0,
        },
        client: {
          id: 1,
        },
        ecopoint: 100,
      };

      if (!this.selectedClient.user) {
        Swal.fire({
          title: 'Oops!',
          text: 'Por favor selecione um cliente!',
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
        return;
      }

      data.client.id = this.selectedClient.id;
      data.ecopoint = parseFloat(this.removeCurrency(this.cashback)) * 100;
      this.isLoading = true;
      this.partnerExchangeController
        ._getCatalogPartnerByUser(this.selectedPartner.id)
        .then((el: any) => {
          data.catalogPartner.id = el.data.id;
          this.partnerExchangeController
            ._postExchangeEcopoint(data)
            .then((res: any) => {
              this.isLoading = false;
              Swal.fire({
                customClass: {
                  actions: 'modal-actions',
                  confirmButton: 'modal-btn modal-btn-confirm',
                  cancelButton: 'modal-btn modal-btn-cancel',
                },
                html:
                  '<div class="modal-popup">' +
                  '<div class="modal-popup-header">' +
                  '<div class="modal-logo"></div>' +
                  '<p class="modal-title-confirmation"> Resgate de Cashback </p>' +
                  '</div>' +
                  '<div class="modal-container">' +
                  '<div class="modal-container-header">' +
                  '<p> Dados do cliente: </p>' +
                  '<p> Nome: ' +
                  this.selectedClient.user.name +
                  '</p>' +
                  '<p> CPF: ' +
                  this.returnCpf(this.selectedClient.user.cpf) +
                  '</p>' +
                  '</div>' +
                  '<div class="modal-confirmation-info">' +
                  '<p class="modal-bold"> Data: </p>' +
                  '<p>' +
                  this.returnActualDate() +
                  '</p>' +
                  '<p class="modal-bold"> Casback resgatado: </p>' +
                  '<p> R$' +
                  this.cashback +
                  '<p class="modal-bold"> Funcionário: </p>' +
                  this.selectedPartner.user.name +
                  '</p>' +
                  '</div>' +
                  "<button class='modal-btn-print modal-noprint' onclick='window.print()'> Imprimir Recibo de Resgate</button>" +
                  '</div>' +
                  '</div>' +
                  '</div>',
                confirmButtonText: 'Ok',
                showCancelButton: false,
                confirmButtonColor: '#11592E',
                reverseButtons: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.fire({
                    title: 'Yay!',
                    text: 'Resgate de Cashback realizado com sucesso!',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                  });
                  this.selectedClient = {};
                  this.clientCpf = '';
                  this.amountCashbackValue = 0;
                  this.cashback = 0;
                }
              });
              this.searchClient();
            })
            .catch((error: any) => {
              this.isLoading = false;
              let message = '';
              if (error.response.data.mensagem) {
                message = error.response.data.mensagem;
              } else {
                message = error.response.data.error;
              }

              Swal.fire({
                title: 'Oops!',
                text: message,
                icon: 'warning',
                confirmButtonText: 'Ok',
              });
            });
        });
    },
    returnActualDate() {
      let date = new Date();
      let month = date.getMonth() + 1;
      let finalMonth;
      if (month < 10) {
        finalMonth = `0${month}`;
      } else {
        finalMonth = month
      }
      let day = date.getDate();
      let finalDay;
      if (day < 10) {
        finalDay = `0${day}`;
      } else {
        finalDay = day;
      }
      let finalDate = `${finalDay}/${finalMonth}/${date.getFullYear()}`;

      return finalDate;
    },
    returnFormatReal(data: any) {
      this.cashback = formatReal(data.target.value);
    },
    removeCurrency(data: any) {
      return removeCurrency(data);
    },
    returnCurrencyWithoutSymbol(currency: number) {
      return formatCurrencyWithoutSymbol(currency);
    },
  },
})
export default class PointsExchangePartner extends Vue {}
