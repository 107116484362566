export default class RouterGuards {
  public async getRoutes(role: string) {
    let data;
    if (role != undefined) {
      await this.routes.forEach((item: any) => {
        if (item.role === role) {
          data = item.routes;

          data.push(
            {
              show: false,
              icon: "sair",
              name: "Home",
              route: "/app/home",
              dropdown: [],
              isNew:false,
            },
            {
              show: true,
              icon: "minhaConta",
              name: "Minha conta",
              route: "/app/account",
              dropdown: [],
              isNew:false,
            }
          );
        }
      });
      return data;
    }
  }

  public getRole(role: string, route: string) {
    let data = false;
    this.routes.forEach((item: any) => {
      if (item.routes) {
        item.routes.forEach((e: any) => {
          if (role === item.role && route == e.route) {
            data = true;
          }
        });
      }
    });

    return data;
  }

  routes = [
    {
      role: "ROLE_CLIENT",
      routes: [
        {
          show: true,
          icon: "localizePostos",
          name: "Localize Postos",
          route: "/app/localize-postos",
          dropdown: [],
          isNew:false,
        },
        {
          show: true,
          icon: "pegadaDeCarbono",
          name: "Pegada de carbono",
          route: "/app/minha-pegada-de-carbono",
          dropdown: [],
          isNew:false,
        },
        {
          show: true,
          icon: "cashback",
          name: "Cashback",
          route: "/app/cashback",
          dropdown: [],
        },
        {
          show: false,
          icon: "lojasParceiras",
          name: "Lojas parceiras",
          route: "/app/lojas-parceiras",
          dropdown: [],
          isNew:false,
        },
        {
          show: false,
          icon: 'lojasParceiras',
          name: 'Lojas parceiras',
          route: '/app/lojas-parceiras',
          dropdown: [],
          isNew:false,
        },
        {
          show: true,
          icon: 'meuConsumo',
          name: 'Meu consumo',
          route: '/app/meu-consumo',
          dropdown: [],
          isNew:false,
        },
      ],
    },
    {
      role: "ROLE_ADMIN",
      routes: [
        {
          show: true,
          icon: "minhaConta",
          name: "Usuarios",
          route: "/app/admin/users",
          dropdown: [
            {
              show: true,
              icon: "",
              name: "Frentista",
              route: "/app/admin/users/attendant",
            },
            {
              show: true,
              icon: "",
              name: "Responsável Loja",
              route: "/app/admin/users/partner",
            },
            {
              show: true,
              icon: "",
              name: "Gerente Posto",
              route: "/app/admin/users/manager",
            },
            {
              show: true,
              icon: "",
              name: "Admin",
              route: "/app/admin/users/admin",
            },
            {
              show: true,
              icon: "",
              name: "Motorista",
              route: "/app/admin/users/client",
            },
          ],
        },
        {
          show: true,
          icon: "posto",
          name: "Postos",
          route: "/app/admin/gas-stations",
          dropdown: [
            {
              show: true,
              icon: "",
              name: "Posto",
              route: "/app/admin/gas-stations",
            },
            {
              show: true,
              icon: "",
              name: "Rede de Postos",
              route: "/app/admin/gas-station-network",
            },
          ],
        },
        {
          show: true,
          icon: "store",
          name: "Loja",
          route: "/app/admin/stores",
          dropdown: [],
          isNew:false,
        },
        {
          show: true,
          icon: "mensalidade",
          name: "Mensalidade",
          route: "/app/admin/monthly-payment",
          dropdown: [],
          isNew:false,
        },
        {
          show: true,
          icon: "CO2",
          name: "Carbono",
          route: "/app/admin/carbon",
          dropdown: [],
          isNew:false,
        },
        {
          show: true,
          icon: "metodologia",
          name: "Metodologia",
          route: "/app/admin/methodology",
          dropdown: [],
          isNew:false,
        },
      ],
    },
    {
      role: "ROLE_MANAGER",
      routes: [
        {
          show: true,
          icon: "dashboard",
          name: "Dashboard",
          route: "/app/dashboard-posto",
          dropdown: [],
          isNew:false,
        },
        {
          show: true,
          icon: 'metas-icon',
          name: 'Metas',
          route: '/app/metas',
          dropdown: [],
          isNew:true,
        },
        {
          show: true,
          icon: "ranking-icon",
          name: "Ranking",
          route: "/app/ranking",
          dropdown: [],
        },
        {
          show: false,
          icon: "precos",
          name: "Preços",
          route: "/app/atualizacao-precos",
          dropdown: [],
          isNew:false,
        },
        {
          show: true,
          icon: "meuConsumo",
          name: "Resgates de Cashback",
          route: "/app/extrato-cashback",
          dropdown: [],
          isNew:false,
        },
        {
          show: true,
          icon: "frentistas",
          name: "Frentistas",
          route: "/app/frentistas",
          dropdown: [],
          isNew:false,
        },    
        {
          show: true,
          icon: 'trainings',
          name: 'Treinamentos',
          route: '/app/treinamentos',
          dropdown: [],
          isNew:false,
        },
      ],
    },
    {
      role: "ROLE_ATTENDANT",
      routes: [
        {
          show: true,
          icon: "consumoClientes",
          name: "Consumo Clientes",
          route: "/app/compra-cliente",
          dropdown: [],
          isNew:false,
        },
        {
          show: true,
          icon: "trainings",
          name: "Treinamentos",
          route: "treinamentos",
          dropdown: [],
          isNew:false,
        },
      ],
    },
    {
      role: "ROLE_PARTNER",
      routes: [
        {
          show: true,
          icon: "cashback",
          name: "Transações",
          route: "/app/transacoes",
          dropdown: [],
          isNew:false,
        },
        {
          show: true,
          icon: "lojasParceiras",
          name: "Registro de Transações",
          route: "/app/registro-transacoes",
          dropdown: [],
          isNew:false,
        },
      ],
    },
  ];
}
